import FullCalendar from "@fullcalendar/react"
import React, { useEffect, useRef, useState } from "react"
import Scheduler from "../../../scheduler/Scheduler"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { CustomContentGenerator, EventContentArg } from "@fullcalendar/core"
import moment from "moment-timezone"
import Checkbox from "../../../atoms/checkbox"
import Modal from "../../../molecules/modal"
import TimePicker from "../../../time-picker/TimePicker"
import InputContainer from "../../../fundamentals/input-container"
import InputField from "../../../molecules/input"
import InputHeader from "../../../fundamentals/input-header"
import TextArea from "../../../molecules/textarea"
import { useForm } from "react-hook-form"
import InputError from "../../../atoms/input-error"

interface IEventModalProps {
  title?: string
  start: moment.Moment
  setStart: (e) => void
  end: moment.Moment
  setEnd: (e) => void
  onSave?: (e: moment.Moment[], title: string, content?: string) => void
  open: boolean
  onClose: () => void
  selectedDate: moment.Moment
}

const ModalEvent: React.FC<IEventModalProps> = (props: IEventModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<{
    title: string
    content: string
  }>()
  // const [startDate, setStartDate] = useState<moment.Moment>(props.start);
  // const [endDate, setEndDate] = useState<moment.Moment>(props.end);

  // useEffect(() => {
  //   console.log(startDate.format('DD/MM/YYYY HH:mm'))
  // }, [start])

  // useEffect(() => {
  //   console.log(endDate.format('DD/MM/YYYY HH:mm'))
  // }, [endDate])

  return (
    <Modal
      open={props.open}
      handleClose={() => {
        return null
      }}
    >
      <Modal.Header handleClose={() => props.onClose()}>
        <div className="font-bold text-lg">
          Add event on {props.selectedDate?.format("DD/MM/YYYY")}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="p-6 ">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <TimePicker
                label="Start time"
                date={props.start}
                onSubmitDate={(e) => {
                  props.setStart(e)
                }}
              />
            </div>
            <div>
              <TimePicker
                label="End time"
                date={props.end}
                onSubmitDate={(e) => {
                  props.setEnd(e)
                }}
              />
            </div>
          </div>

          <InputField
            label="Title"
            placeholder="Event name"
            className="border-none"
            required={true}
            {...register("title", {
              validate: (e) => {
                if (!e || e.length === 0) return "Required field"

                return
              },
            })}
            errors={errors}
          />

          <TextArea
            label="Content Event"
            placeholder="Content"
            {...register("content")}
          />

          <div className="text-right mt-2">
            <button
              onClick={async () => {
                if (!(await trigger())) return
                const values = getValues()

                props.onSave &&
                  props.onSave(
                    [props.start, props.end],
                    values.title,
                    values.content
                  )
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const AppointmentsView: React.FC = () => {
  const [currentEvents, setCurrentEvents] = useState<any[]>([])
  const [addEventModal, setAddEventModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment())
  // const [calendarApi, setCalendarApi] = useState<any>(null)
  const calendarRef = useRef<null>()
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [startDate, setStartDate] = useState<moment.Moment>(moment())

  // useEffect(() => {
  //   console.log(currentEvents);
  //   const calendarApi = calendarRef.current.getApi()
  //   console.log(calendarApi)
  //   calendarApi.render()
  // }, [currentEvents])

  const saveEvent = (e: moment.Moment[], title: string, content?: string) => {
    const calendarApi = calendarRef.current.getApi()
    // console.log(currentEvents)
    calendarApi.unselect()

    calendarApi.addEvent({
      id: moment().unix(),
      title: JSON.stringify({ title, content }),
      start: e[0]?.tz("Asia/Ho_Chi_Minh").toISOString(true),
      end: e[1]?.tz("Asia/Ho_Chi_Minh").toISOString(true),
      // allDay: selectInfo.allDay
      allDay: false,
    })

    setAddEventModal(false)
  }

  const handleDateSelect = (selectInfo) => {
    setEndDate(moment(selectInfo.endStr))
    setStartDate(moment(selectInfo.startStr))
    setSelectedDate(moment(selectInfo.startStr))
    // if (!calendarApi)
    //   setCalendarApi(selectInfo.view.calendar)

    setAddEventModal(true)
  }

  const renderEventContent = (eventInfo: any) => {
    console.log(eventInfo)
    return (
      <React.Fragment>
        <div
          className={`bg-white text-blue-500 ${
            eventInfo.isFuture
              ? "bg-green-400"
              : eventInfo.isEnd
              ? "bg-red-300"
              : "bg-yellow-300"
          } border h-full rounded`}
        >
          <div className="font-bold text-white">
            {eventInfo.event.title
              ? JSON.parse(eventInfo.event.title).title
              : null}
          </div>
          <div className="text-white">{eventInfo.timeText}</div>
          <div className="text-white">
            {eventInfo.event.title
              ? JSON.parse(eventInfo.event.title).content
              : null}
          </div>
        </div>
      </React.Fragment>
    )
  }

  const handleEventClick = (clickInfo: any) => {
    const temp = currentEvents.filter((e) => e.id !== clickInfo.event.id)

    setCurrentEvents(temp)
    // console.log(temp)
    // console.log(clickInfo.view.calendar)
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }
  }

  const handleEvents = (events) => {
    setCurrentEvents(events)
  }

  return (
    <React.Fragment>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-3">
          <div className="bg-white shadow-sm rounded-lg py-2">
            <div className="px-4 mb-2 font-extrabold ">Filter</div>
            <div className="px-2">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i, index) => {
                return (
                  <div key={index} className={`py-2 px-2 bg-white`}>
                    <Checkbox className="w-4" label="Name" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="col-span-9">
          <FullCalendar
            eventAdd={(e) => {}}
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            dayHeaderContent={function (e) {
              // console.log(
              return moment(e.date.toISOString()).format("DD/MM")
            }}
            // headerToolbar={{
            //   left: 'prev,next today',
            //   center: 'title',
            //   right: 'dayGridMonth,timeGridWeek,timeGridDay'
            // }}
            initialView="timeGridWeek"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents}
            he
          />
        </div>
      </div>

      <ModalEvent
        title={`Add event on ${selectedDate?.format("DD/MM/YYYY")}`}
        open={addEventModal}
        onClose={() => {
          setAddEventModal(false)
        }}
        onSave={saveEvent}
        start={startDate}
        setStart={setStartDate}
        end={endDate}
        setEnd={setEndDate}
        selectedDate={selectedDate}
      />
    </React.Fragment>
  )
}

export default AppointmentsView
