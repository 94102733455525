import { Router } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useHotkeys } from "react-hotkeys-hook"
import PrivateRoute from "../../components/private-route"
import SEO from "../../components/seo"
// import Layout from "../../components/templates/layout"
import Layout from "../../components/layout/store/Layout"
import Collections from "../../domain/collections"
import Customers from "../../components/views/retail/customers"
import Discounts from "../../domain/discounts"
import GiftCards from "../../domain/gift-cards"
import Oauth from "../../domain/oauth"
import Orders from "../../domain/orders"
import DraftOrders from "../../domain/orders/draft-orders"
import Pricing from "../../domain/pricing"
import ProductsRoute from "../../domain/products"
import SalesChannels from "../../domain/sales-channels"
import Settings from "../../components/views/retail/settings"
import AppointmentsView from "../../components/views/dashboard/store/appointments"

const AppointmentsPage = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Layout>
        <AppointmentsView />
      </Layout>
    </DndProvider>
  )
}

export default AppointmentsPage
